import * as React from 'react'
import { graphql } from 'gatsby'
import format from 'date-fns/format'
import styled from 'styled-components'
import { SEO } from '../components/seo'

const POSTS_PER_PAGE = 12

import {
  transform as toBlogPost,
  ContentfulTemplateBlogPost,
  BlogPost,
} from '../data/blog-post'

import {
  transform as toGlobals,
  ContentfulSettingsDesignerFund,
  Globals,
} from '../data/globals'

import { PostCardFull, PostCard } from '../components/card'
import { NewsletterHuge } from '../components/newsletter'
import { ResourcesSection } from '../components/section'
import { BlogHeader } from '../components/section'
import { Button } from '../components/button'

interface Props {
  data: {
    allContentfulTemplateBlogPost: {
      edges: { node: ContentfulTemplateBlogPost }[]
    }
    allContentfulSettingsDesignerFund: {
      edges: { node: ContentfulSettingsDesignerFund }[]
    }
  }
}

type State = {
  page: number
  posts: BlogPost[]
  settings: Globals
}

export default class BlogListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    const {
      allContentfulTemplateBlogPost,
      allContentfulSettingsDesignerFund,
    } = this.props.data
    const posts = allContentfulTemplateBlogPost.edges.map(({ node }) =>
      toBlogPost(node)
    )
    const [settings] = allContentfulSettingsDesignerFund.edges.map(({ node }) =>
      toGlobals(node)
    )

    this.state = { page: 1, posts, settings }
  }

  public render() {
    const { page, posts, settings } = this.state
    const [header, feature1, feature2, rest] = [
      posts.slice(0, 4),
      posts.slice(4, 6),
      posts.slice(6, 8),
      posts.slice(8),
    ]

    return (
      <>
        <SEO />
        <BlogHeader posts={header} />
        <FeaturedPostsSection posts={feature1} />
        <NewsletterHuge
          label="We wanted to increase sign-ups so we made the cta bigger — enter your email to stay informed 🔮"
          placeholder="Enter email address"
          inputId="blog-inline"
        />
        <FeaturedPostsSection posts={feature2} />

        <PaginatedGridOfPosts
          posts={rest}
          pageLength={POSTS_PER_PAGE}
          page={page}
          onLoadMore={() => this.setState(s => ({ page: s.page + 1 }))}
        />

        <ResourcesSection title="Resources" resources={settings.resources} />
      </>
    )
  }
}

const StyledFeaturedSection = styled.section`
  --sfs-padding-y: 55px;

  padding: var(--sfs-padding-y) 0;

  .container:not(:last-child) {
    margin-bottom: var(--sfs-padding-y);
  }

  @media (min-width: 768px) {
    --sfs-padding-y: 112px;
  }
`

function FeaturedPostsSection({ posts }: { posts: BlogPost[] }) {
  return (
    <StyledFeaturedSection>
      {posts.map((post, idx) => {
        const date = format(post.publishDate, 'MMMM d, yyyy')
        return (
          <div key={post.slug} className="container container--grid">
            <div className="container__row">
              <PostCardFull
                slug={post.slug}
                title={post.title}
                subtitle={`${date} | ${post.author.name}`}
                description={post.description}
                reverse={!!(idx % 2)}
                image={post.hero}
              />
            </div>
          </div>
        )
      })}
    </StyledFeaturedSection>
  )
}

const StyledGridOfPosts = styled.div`
  --sgop-num-cols: 2;
  --sgop-grid-gap: 24px;
  --sgop-margin-b: 48px;

  display: grid;
  grid-template-columns: repeat(var(--sgop-num-cols), 1fr);
  grid-gap: var(--sgop-grid-gap);
  margin-bottom: var(--sgop-margin-b);

  @media (min-width: 768px) {
    --sgop-num-cols: 3;
    --sgop-grid-gap: 24px;
  }

  @media (min-width: 1024px) {
    --sgop-margin-b: 70px;
    --sgop-grid-gap: 42px;
  }

  @media (min-width: 1280px) {
    --sgop-num-cols: 4;
  }
`

function PaginatedGridOfPosts(props: {
  posts: BlogPost[]
  pageLength: number
  page: number
  onLoadMore: () => any
}) {
  const visible = props.pageLength * props.page

  return (
    <div className="container container--grid">
      <div className="container__row">
        <StyledGridOfPosts>
          {props.posts.slice(0, visible).map(post => {
            const d = format(post.publishDate, 'MMMM d, yyyy')
            return (
              <div key={post.slug}>
                <PostCard
                  title={post.title}
                  subtitle={`${d} | ${post.author.name}`}
                  slug={post.slug}
                  image={post.hero}
                />
              </div>
            )
          })}
        </StyledGridOfPosts>
        {visible >= props.posts.length ? null : (
          <div>
            <Button
              onClick={props.onLoadMore}
              appearance="secondary"
              size="small"
              fill
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  query BlogIndexPage {
    allContentfulTemplateBlogPost(
      filter: { slug: { ne: null } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPostFields
        }
      }
    }
    allContentfulSettingsDesignerFund(limit: 1) {
      edges {
        node {
          ...GlobalsFields
        }
      }
    }
  }
`
